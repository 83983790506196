<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col col="6" offset="0">
          <h1>{{ name }}</h1>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="getCostEvaluationData"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="reloadPage">
  <v-icon>  {{ icons.mdiReload }}</v-icon>
</v-btn>
          <v-dialog v-model="editmodal" max-width="500px">
            <v-card>
              <v-card-title>{{ currentItem.name }}</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="currentItem.status"
                        :items="statuses"
                        item-text="name"
                        item-value="value"
                        label="Status"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn block depressed color="primary" @click="updateData">
                  UPDATE
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" fullscreen hide-overlay>
            <v-card>
              <v-toolbar>
                <v-btn icon @click.native="close">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <v-toolbar-title>Medien zum Vorgang: {{ selectedName }} </v-toolbar-title>
              </v-toolbar>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
              <v-container class="my-5">
                <div>
                  <v-row>
                    <v-col
                      v-for="(src, i) in imageSources"
                      :key="i"
                      class="d-flex child-flex"
                      cols="4"
                    >
                    <div v-if="src.includes('.mp4')" @click="selectedItem = src" >
                      <video
                        
                        width="640"
                        height="480"
                        controls
                        aspect-ratio="1.7778"
                      >
                        <source :src="src" type="video/mp4" />
                      </video>
                    </div>
                      <v-img
                        :src="src"
                        contain
                        class="grey lighten-2"
                        @click="selectedItem = src"
                        v-else
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
              <v-overlay v-if="selectedItem && !selectedItem.includes('.mp4') ">
                <v-img
                  :src="selectedItem? selectedItem :''"
                  contain
                  @click="selectedItem = null"
                ></v-img>
              </v-overlay>
              <v-overlay v-if="selectedItem && selectedItem.includes('.mp4') ">
                <video
                  width="1280"
                  height="720"
                  controls
                  aspect-ratio="1.7778"
                  @click="selectedItem = null"
                >
                  <source
                    :src="selectedItem? selectedItem :''"
                    type="video/mp4"
                  />
                </video>
                <v-img
                  :src="selectedItem? selectedItem :''"
                  contain
                  @click="selectedItem = null"
                ></v-img>
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon
          dark
          color="warning"
          v-if="item.status == 1"
          @click="printItem(item)"
        >
          {{ icons.mdiAlertCircle }}
        </v-icon>
        <v-icon
          ddark
          color="success"
          v-else-if="item.status == 0"
          @click="printItem(item)"
        >
          {{ icons.mdiCheckCircle }}
        </v-icon>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.image_path != null && item.image_path != ''"
          class="mx-2"
          fab
          dark
          small
          rounded
          color="primary"
          @click="showImage(item, item.name)"
        >
          <v-icon dark>
            {{ icons.mdiCameraImage }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiCameraImage,
  mdiCheckCircle,
  mdiAlertCircle,
  mdiPencil,
    mdiClose,
    mdiReload

} from "@mdi/js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: { // Add a prop to receive the name
    selectedName: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data() {
    return {
      name: "Kostenvoranschlag",
      selectedItem: null,
      visible: false,
      icons: {
        mdiCameraImage,
        mdiCheckCircle,
        mdiAlertCircle,
        mdiPencil,
          mdiClose,
          mdiReload

      },
      statuses: [
        {
          name: "Aktiv",
          value: 1,
        },
        {
          name: "Inaktiv",
          value: 0,
        },
      ],
      search: "",
      headers: [
        {
          text: "Medien",
          align: "start",
          filterable: false,
          value: "action",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Telefon", value: "phone" },
        { text: "Label", value: "custom_label" },
        { text: "Trans-Nr", value: "trans_token" },
        { text: "Angelegt", value: "created_at" },
        { text: "Update", value: "updated_at" },
        { text: "Vorg. abgeschl.", value: "status" },
      ],
      currentItem: {
        id: null,
        name: "",
        email: "",
        custom_label: "",
        trans_token: "",
        status: null,
      },
      defaultItem: {
        id: null,
        name: "",
        email: "",
        custom_label: "",
        trans_token: "",
        status: null,
      },
      dialog: false,
      editmodal: false,
    };
  },
  computed: {
    ...mapGetters("main", ["getCostEvaluationData", "imageSources"]),
  },
  methods: {
    ...mapActions("main", [
      "loadCostEvaluationData",
      "updateDataset",
      "retrieveImageSources",
    ]),
    reloadPage() {
      window.location.reload();
    },
    printItem(item) {
      this.currentItem = Object.assign({}, item);
      this.editmodal = true;
    },
    updateData() {
      console.log(this.currentItem);
      this.updateDataset(this.currentItem, 1);
      this.close();
    },
    showImage(item) {
  this.currentItem = { ...this.defaultItem, ...item }; // Simplified object assignment
  this.retrieveImageSources(item.id);
  this.dialog = true;
  this.selectedName = item.name; // Ensure the selectedName is updated with the item's name
},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.currentItem = Object.assign({}, this.defaultItem);
      });
    },
    carouselClose() {
      this.carousel = false;
      this.image_sources = [];
    },
     show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  },
  mounted() {
    this.$store.dispatch("main/loadCostEvaluationData");
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    carousel(val) {
      val || this.carouselClose();
    },
  },
};
</script>
